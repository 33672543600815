// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.3
//   protoc               v5.28.2
// source: organization/v1/enums/organizationType.proto

/* eslint-disable */

export const protobufPackage = "organization.v1.enums.organizationType";

export enum EOrganizationType {
  Unspecified = 0,
  Dealership = 1,
  Company = 2,
  UNRECOGNIZED = -1,
}

export function eOrganizationTypeFromJSON(object: any): EOrganizationType {
  switch (object) {
    case 0:
    case "Unspecified":
      return EOrganizationType.Unspecified;
    case 1:
    case "Dealership":
      return EOrganizationType.Dealership;
    case 2:
    case "Company":
      return EOrganizationType.Company;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EOrganizationType.UNRECOGNIZED;
  }
}

export function eOrganizationTypeToJSON(object: EOrganizationType): string {
  switch (object) {
    case EOrganizationType.Unspecified:
      return "Unspecified";
    case EOrganizationType.Dealership:
      return "Dealership";
    case EOrganizationType.Company:
      return "Company";
    case EOrganizationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
